.keep-or-cancel-selection {
    display: flex;
    align-items: center;
    
}

.opt-options-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
 /* This ensures the spacing is distributed */
}

.opt-options-inline input[type="radio"] {
    margin-right: 5px; /* Adds 5px space between the radio button and label */
}


.opt-options-inline label {
    margin-right: 20px; /* Adds 20% space between "Keep" and "Cancel" */
}

