.cici_nextButton_border {
    display: flex;
    /* min-width: 216px; */
    padding: 4px 16px 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background: #FFF;
    width: calc(100%);
}

.cici_nextButton_button{
    display: flex;
    height: 43px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 3px;
    background: var(--Primary-Main, #2D77A3);
    border: none;
}

.cici_nextButton_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    color: #FFF;
    text-align: center;

/* Desktop/Button */
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}