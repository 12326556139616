.header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.header-title__text {
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
}

.header-title__icon {}