.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipIcon {
  cursor: pointer;
  width: 13px;
  height: 13px;
  margin-bottom: 3px;
  margin-left: 4px;
}

.tooltipMessage {
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px;
  background: var(--CalHEERS-Primary-Text, #334048);
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  color: var(--Primaries-White, #FFF);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 21px; /* 150% */
  text-align: left;
  width: var(--tooltip-width, 20px); /* Default width */
  height: var(--tooltip-height, auto); /* Default height */
}

.tooltipMessageTop.en {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.tooltipMessageTop.es {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}
.tooltipMessageBottom.en {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.tooltipMessageBottom.es {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.tooltipMessageLeft.en {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;
}

.tooltipMessageLeft.es {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;
}
.tooltipMessageRight.en {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}
.tooltipMessageRight.es {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}

/* @media (min-width: 765px) and (max-height: 1025px) {
  .tooltipMessageLeft.es {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
} */

/* @media (max-width: 480px) {
  .tooltipMessage {
    width: var(--tooltip-width-small-mobile, 150px) !important;
    height: var(--tooltip-height-small-mobile, auto); 
  }
} */
