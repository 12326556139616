.wizard {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 50px;
}

.wizard__header {
  width: 100%;
  position: absolute;
  margin: -20px -20px 0 -20px;
  display: flex;
  justify-content: flex-end;
}

.wizard__content {
  margin: -20px -20px 0 -20px;
  padding-top: 64px;
  /* flex-grow: 1; */
  height: calc(100% - 125px); 
  overflow-y: auto; 
  padding: 20px;
  z-index: 1;
}

.wizard ul {
  margin: 0;
  padding-left: 20px;
}

.wizard p {
  margin: 0;
}

.wizard ul li {
  padding-bottom: 16px;
}

.overlay {
  position: absolute;
    top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:red; 
  z-index: 1000;
}
.wizard__content::-webkit-scrollbar {
  width: 4px;
  background-color: #BABABC;
  border-radius: 4px;
  /* display: none;  */
}

/* .wizard__content::-webkit-scrollbar::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 51px;
  background: linear-gradient(to top, white, transparent);
  pointer-events: none;
} */
/* .wizard__content:hover::-webkit-scrollbar {
  display: block; 
} */

.wizard__navigation {
  margin-top: auto;
}

.wizard__navigation-button-container {
  margin-bottom: 16px;
}

.wizard__navigation-button-container:last-child {
  margin-bottom: 0;
}

.overlaybottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -38px;
  height: 112px; 
  border-radius: 0px 0px 9px 9px;
  background:  linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 39.11%);
  pointer-events: none; 
  z-index: 2;
}

.overlaybottom--first {
  bottom: -106px;
  height: 125px;
}

.overlaybottom--passcode {
  bottom: -29px !important;
  height : 24px !important
}