:root{
  header-max-height: 64px;
  header-margin-bottom: 32px;
  footer-max-height: 75px;
  body-max-height: calc(100% - var(header-max-height) - var(header-margin-bottom) - var(footer-max-height));
}

.chat-menu {
  position: absolute;
  z-index: 2;
  height: calc(100% - 56px);
  max-height: calc(100% - 56px);
  width: 100%;
  top: 56px;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  visibility: visible;
  transition: 0.3s;
}

.chat-menu--inactive {
  transform: translateX(-110%);
  -webkit-transform: translateX(-110%);
  visibility: hidden;
}

.chat-menu__header {
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.chat-menu__header-back-button-container {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-self: center;
}

.chat-menu__header-back-button {
  padding-left: 16px;
  padding-right: 16px;
  background-color: white;
  border: none;
}

.chat-menu__header-text {
  font-size: 21px;
  font-weight: bold;
}

.chat-menu__body {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: var(body-max-height);
  overflow-y: auto;
}

.chat-menu__content {}

.chat-menu__items-title {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.chat-menu__items {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}

.chat-menu__item {
  border: none;
  background-color: white;
  color: #2d77a3;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 14px;
  border-bottom: 1px solid #d1d1d1;
}

.chat-menu__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.chat-menu__main-actions {
  flex-grow: 1;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
}

.chat-menu__main-action {
  border: none;
  background-color: #2d77a3;
  color: white;
  height: 43px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  margin: auto;
  width: 100%;
}

.chat-menu__footer {
  margin-top: auto;
  background-color: #f2f2f2;
}

.chat-menu__footer-items {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.chat-menu__footer-item {
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: #696969;
  padding-top: 8px;
  padding-bottom: 8px;
}

/**
* Accordion inside chat menu
**/
.chat-menu__accordion{
    border: 5px solid blue !important
}