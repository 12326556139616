html,body{
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  /* font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
