.person-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.person-details {
    display: flex;
    align-items: center;
}

.status-icon {
    margin-right: 8px;
    width: 10.819px;
    height: 6.147px;
    margin-top: -37px;
}

.status-text {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
}


.person-name {
    color: var(--CalHEERS-Primary-Text, #334048);
      font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.person-age {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; 
}

.keep-cancel-status {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
}
