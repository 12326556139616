/* .plan-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.plan-group {
    display: flex;
    flex-direction: column;
}

.plan-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
} */

.plan-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.plan-group {
    display: flex;
    flex-direction: column;
}

.plan-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.group {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 175% */
}

.plan-name {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 150% */
}

.what-you-pay {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 175% */
}

.adjusted-cost {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 100% */
}

.monthly {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}


.info-icon {
    font-style: normal; 
    width: 13px;
    height: 13px;
    margin-top: -3px
}
