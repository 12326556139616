.welcome-screen{
    position: absolute;
    height: calc(100% - 40px);
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

@media only screen and (min-width: 576px) {
    /* .welcome-screen{
        position: absolute;
        overflow-y: auto;
    } */
}
.welcome-screen__image-container{
    margin-bottom: 8px;
}

.welcome-screen__heading{
    width: 100%;
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    margin-top: 8px;
}
.welcome-screen__sub-heading{
    font-size: 14px;
    width: 100%;
    /* margin-bottom: 32px; */
    line-height: 150%;
}

.welcome-screen__actions-container{
    margin-left: -8px;
    margin-right: -8px;
}