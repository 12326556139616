.input-validation-message {
    color: #E22E3F;
    font-size: 14px;
    margin-top: 8px;
    font-style: italic;
}

.otp-validation-message {
    color: #E22E3F;
    font-size: 14px;
    margin-top: -24px !important;
    margin-bottom: 32px !important;
    font-style: italic;
  }
  