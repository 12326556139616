.footer {
    position: relative;
    height: 100%;
    width: 100%;
}

.footer__language-options {
    height: 100%;
    width: 100%;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
}