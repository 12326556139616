.liveChatCard-container{
    padding: 15px;
}

.liveChatCard-fields{
    padding: 4px;
}

.liveChatCard-fields-dropdown{
    padding: 7px;
}

.liveChatCard-fields__outer{
    padding-top: 4px;
    padding-bottom: 4px;
}

.liveChatCard-label{
    font-size:15px;
}

.liveChatCard-label-small{
    font-size:12px;
    padding-bottom: 8px;
}


.liveChatCard-fields__label{
    margin-bottom: 0!important;
}