.planCard {
  width: 100%;
  color: #334048;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  padding-bottom: 24px;
}

  .planCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .planType {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
  }

  .status {
    display: flex;
    align-items: center;
  }

  .statusDot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .statusText {
    margin: 0;
    font-size: 14px;
  }

  .planCardDivider {
    margin: 8px 0;
    border: 0;
    border-top: 1px solid #D1D1D1;
  }

  .planCardBody {
    margin-top: 8px;
  }

  .planDetail {
    margin: 8px 0;
  }

  .planDetailLabel {
    display: block;
    font-weight: bold;
    font-size: 12px;
  }

  .planDetail span {
    display: block;
    font-size: 12px;
    color: #334048;
  }
