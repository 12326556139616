.cici_edit {
    border-radius: 20px;
    border: 1px solid var(--Primary-Main, #2D77A3);
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    /* width:  calc(100%); */
    width: auto;
    background-color: white;
}

.cici_editText {
    color: var(--Primary-Main, #2D77A3);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}

.cici_editImage {
    width: 14px;
    height: 14px;
    margin-top: 2px;
}