@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");

.cici_subHeader_contianer {
  display: flex;
  height: 22px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
}

.cici_subHeader_typography {
  color: var(--CalHEERS-Primary-Text, #334048);
  font-family: "Open Sans";
  font-size: 21px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 135%;
}
