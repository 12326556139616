.explanationToggle {
    margin-bottom: 1rem;
  }
  
  .toggleHeader {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--CalHEERS-Main-Blue, #2D77A3);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal !important;
    font-weight: 400;
    line-height: 150%;
  }
  
  .toggleHeading {
    margin: 0;
    margin-right: 8px; 
    display: inline; 
    font-weight: normal !important;
    font-size: 14px
  }
  
  .toggleIcon {
    cursor: pointer;
    width: 8px;
    height: 5px;
    fill: var(--CalHEERS-Main-Blue, #2D77A3);
    margin-bottom: -4px;
  }
  
  .messageDetails {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
  }
  