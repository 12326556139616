.panel {
    padding: 20px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    margin-bottom: 24px;
}

.panel__header {
    font-size: 18px;
    color: #334048;
    font-weight: 700;
    border-bottom: 1px solid #D1D1D1;
    padding-bottom: 12px;
    line-height: 28px;
}

.panel__content {
    padding-top: 12px;
    line-height: 21px;
}