.page {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.page__header {}

.page__content {}

.page__navigation {
    margin-top: auto;
}

.page__navigation-button-container {
    margin-bottom: 16px;
}

.page__navigation-button-container:last-child {
    margin-bottom: 0;
}