.input__text {
    color: var(--Text-Primary, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    outline: none !important;
    width: 100%;
    border: 1px solid var(--Grayscale-Gray---200, #BABABC);
    display: flex;
    padding: 10.5px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    background: var(--Background-Default, #FFF);
}

.input__text::placeholder {
    font-style: italic;
    color: var(--Text-Inactive, #949494);
}

.input__text--normal {
    font-style: normal;
}

.input__text--italic {
    font-style: italic;
}

.input__text--invalid {
    border: 1px solid red;
}

.cici_input_container_invalid {
    display: flex;
    padding: 10.5px 12px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    width: calc(100%);
    border: 1px solid red;
    background: var(--Background-Default, #FFF);
}

.cici_input_container_invalid_feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    color: var(--Error-Main, #E22E3F);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
}

.cici_input_container_valid_feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545 !important;
}
