.cca-chatbot {
  position: relative;
  border-radius: 8px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: -4px -4px 20px 0px rgba(0, 0, 0, 0.25);
  z-index: 4;
  background-color: white;

  /**
  * 100vh is wonky in ios use dvh and vh as a backup
  **/
  height: 100dvh;
}

@media only screen and (min-width: 576px) {
  .cca-chatbot {
    width: 360px;
    height: 672px;
    max-height: calc(100vh - 64px);
    margin: 0px;
    margin: 32px;
  }
  /* .cca-chatbot__webchat {
    position: sticky;
    overflow-y: auto;
  } */
}

.cca-chatbot--bordered {
  border: 1px solid #e0e0e0;
}

.cca-chatbot__header {
  height: 56px;
  flex-shrink: 0;
  flex-grow: 0;
}

.cca-chatbot__webchat {
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  height: calc(100% - 101px);
}

.cca-chatbot__wizard {
  position: absolute;
  top: 56px;
  left: 0;
  background-color: aqua;
  width: 100%;
  height: calc(100% - 56px);
  z-index: 1;
}

.css-1m1alj7 {
  display: none;
}

.cca-chatbot__wizardMobile {

  height: 100vh !important;
}
.cca-chatbot__footer {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 45px;
  margin-top: auto;
} 


