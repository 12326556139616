.suggested-action{
    background: none;
    border: 1px solid #2D77A3;
    color: #2D77A3;
    border-radius: 20px;
    margin: 8px;
    padding: 6px 12px;
    text-align: start;
    font-size: 14px;
    font-weight: 600;
}