.header-menu-toggle {
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    border: none;
    outline: none !important;
}

.header-menu-toggle {
    outline: none;
  }
  
  .header-menu-toggle:focus {
    outline: 2px solid #007BFF !important; 
    outline-offset: 2px; 
  }
  