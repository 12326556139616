.chat-menu-accordion{
    border: #D1D1D1;

}

.chat-menu-accordion__item{
    border: none;
    outline: none;
    border-radius: 0;
    border-top: 1px solid !important;
    border-color: #D1D1D1 !important; 
    position: static !important;
    z-index: 5000;
}

.chat-menu-accordion__header{
    padding: 0 ;
    border: none ;
    outline: none;
    border-top: 1px ;
}

.chat-menu-accordion__toggle{
    background-color: white;
    border: none;
    outline: none !important;
    display: flex;
    height: 50px;
    padding: 16px 12px;
    position: relative;
    width: 100%;
    font-weight: 600;
}

.chat-menu-accordion__toggle--active,
.chat-menu-accordion__toggle:hover{
    background-color: #F2F2F2 ;
}