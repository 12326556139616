input.form-control::placeholder {
    color: var(--Text-Inactive, #949494);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    
  }
  
  /* Additional link styles */
  .link-style {
    color: var(--CalHEERS-Main-Blue, #2D77A3);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
  }