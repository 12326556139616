/* EndChatButton.module.css */
.endChatButton {
    background: none;
    border: none;
    font-size: 12px;
    color: #696969;
    border: none;
    padding: 0 10px;
    margin-left: auto;
    position: absolute;
    left: auto;
    right: 10px;
}

.endChatButton:disabled {
    color: #aaa;
    cursor: not-allowed;
    text-decoration: none;
}
