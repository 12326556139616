.plan-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.plan-group {
    display: flex;
    flex-direction: column;
}

.plan-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.plan-text {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}

.group {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}

.plan-name {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; 
}

.what-you-pay {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}

.monthly-cost {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; 
}

.monthly-text {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}
