.navigation-button {
    display: flex;
    height: 43px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 3px;
    border: none;

    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    text-align: center;

    /* Desktop/Button */
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;

    width: 100%;
}

.navigation-button--primary {
    background: var(--Primary-Main, #2D77A3);
    color: #FFF;
}

.navigation-button--secondary {
    border: 1px solid var(--Primary-Main, #2D77A3);
    background: #FFF;
    color: var(--Primary-Main, #2D77A3);
}

.navigation-button--tertiary {
    background: #FFF;
    color: var(--Primary-Main, #2D77A3);
}

.navigation-button--primary-disabled,
.navigation-button--secondary-disabled,
.navigation-button--tertiary-disabled {
    /* background-color: gray !important;
    color: black !important; */
    cursor: not-allowed !important;
}