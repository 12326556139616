@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap);
:root {
  --primary: #2d77a3;
  --mobile-breakpoint: 575px;
  --CalHEERS-Primary-Text: #334048;
  --CalHEERS-Divider-Gray: #d1d1d1;
  --Other-Divider: #d1d1d1;
}
#chatbot-root {
  position: fixed;
  bottom: 0;
  right: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: var(--CalHEERS-Primary-Text);
  font-weight: normal;
}

#chatbot-root * {
  box-sizing: border-box;
}

#chatbot-root button:hover {
  cursor: pointer;
}


/* 
Hides default SuggestedAction component
*/
#chatbot-root .css-nil {
    display: none;
}

/**
* SENDBOX
**/
#chatbot-root .main{
    margin: 0 20px;
    border: solid 1px #E6E6E6;
    border-radius: 4px;
}

/**
* MESSAGE ROW
**/
#chatbot-root .webchat__row{
    margin-left: 10px;
    margin-right: 10px;
}

/**
* USER MESSAGE BUBBLE
**/
#chatbot-root .message .bubble.from-user{
    border-radius: var(--primitive-200, 16px) var(--primitive-200, 16px) 0px var(--primitive-200, 16px);
    border: 1px solid #C7F1FA;
    background: #E4F7F9;
}

/**
* BOT MESSAGE BUBBLE
**/
#chatbot-root .message .bubble{
    margin-top: 16px;
    border-radius: 0px var(--primitive-200, 16px) var(--primitive-200, 16px) var(--primitive-200, 16px);
    border: 1px solid #E4E9ED;
    background: #F1F6F9;
    font-size: 14px;
    max-width: 272px;
    font-family: "Open Sans";
    color: #334048;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
}
#chatbot-root .message .bubble a{
    margin-top: 16px;
    border-radius: 0px var(--primitive-200, 16px) var(--primitive-200, 16px) var(--primitive-200, 16px);
 
    /* background: #F1F6F9;
    font-size: 14px;
    max-width: 272px;
    font-family: "Open Sans"; */
    color: #2D77A3;
    /* font-style: normal;
    font-weight: 400;
    line-height: 135%; */
    text-decoration: underline;
}
#chatbot-root .css-o3xlyv{
    padding: 20px;
}

/**
* BUBBLE TIMESTAMP
**/
.timestamp.transcript-timestamp{
    padding: 12px;
    font-weight: 500 ;
    color: #BABABC;
    margin-right: -12px;
}
html,body{
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  /* font-family: "Roboto","Helvetica Neue",Helvetica,Arial,sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Cici_cici__oYSBt {
    display: block;
}

.Cici_cici--is-minimized__16GI7 .Cici_cici__open-button__3s9Ru {
    display: block;
}

.Cici_cici--is-minimized__16GI7 .Cici_cici__close-button__CeBFk {
    display: block;
}

.Cici_cici--is-minimized__16GI7 .Cici_cici__body__2cxqt {
    display: none;
}

/* .cici__open-button {
    display: none;
    margin: 32px;
    background-color: transparent; 
    border: none; 
} */

.Cici_cici__open-button__3s9Ru {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    margin: 32px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 66px;
    position: relative;
}

.Cici_cici__open-button__3s9Ru:focus {
    outline: none; 
}
.Cici_button__2GmJj:focus {
    outline: none; 
}
/* .cici__open-button img {
    background-color: transparent; 
    border: none; 
    box-shadow: none; 
    width: 83.359px; 
    height: 92.023px;
    flex-shrink: 0; 
} */

.Cici_cici__open-button__3s9Ru img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.Cici_cici__open-text__1YuhL {
    font-size: 12px;
    margin-bottom: 8px; 
    color: #000; 
  
}

.Cici_cici__close-button__CeBFk {
    background-color: #444;
    border-radius: 50%;
    border: none; 
    width: 35px; 
    height: 28px; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25px; 
    right: 35px; 
    cursor: pointer;
    color: white; 
    /* font-size: 24px; */
}

.Cici_cici__close-button__CeBFk:hover {
    background-color: #666; 
}

.Cici_cici__body__2cxqt {
    display: block;
    position: relative;
}

.Cici_cici__open-button__3s9Ru img {
    /* transition: all 0.3s ease; 
    width: 96px;
    height: 42px;
    margin-left: 0;  */
    transition: all 0.3s ease;
    width: 96px;
    height: 42px;
    top: -36px;
    position: absolute;
    right: 2px;
}

.Cici_cici__open-button__3s9Ru img:hover {
    content: url("/cici-assets/static/media/CiCi-Desktop-Hover 1.f718402c.svg"); 
    /* width: 111px;
    height: 50px;
    transform: scale(1.15);
    margin-left: 0;  */
    /* box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.25), 12px 14px 5px 0px rgba(0, 0, 0, 0.00), 8px 9px 5px 0px rgba(0, 0, 0, 0.01), 4px 5px 4px 0px rgba(0, 0, 0, 0.03), 2px 2px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.06); */
    }


@media (max-width: 576px) {
    .Cici_cici__open-button__3s9Ru img {
        content: url("/cici-assets/static/media/CiCi-Default-Avatar-Mobile 1.e13b89be.svg");
        width: 86px;
        height: 36px;
        flex-shrink: 0;
        position: absolute;
        top: -16px;
        margin-right: -8px;
    }

    .Cici_cici__text-box__1orz_{
        margin-right: 16px !important;
        top: -55px !important;
    }
}

.Cici_cici__wrapper__2vrJm {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    position: relative;
    margin-right: -12px; 
    margin-left: 32px; 
}

.Cici_cici__text-box__1orz_ {
    display: flex;
    flex-direction: column;
    background-color: #f1f5f9; 
    border-radius: 8px;
    /* padding: 12px; */
    margin-bottom: -21px;
    position: absolute;
    width: 130px; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    margin-right: 32px;
    border-radius: var(--primitive-200, 16px) var(--primitive-200, 16px) 0px var(--primitive-200, 16px);
    border: 1px solid #C7F1FA;
    background: #E4F7F9;
    padding-left: 16px;
    padding-top: 12px;
    padding-right: 24px;
    padding-bottom: 12px;
    text-align: left;
    margin-left: 36px;
    /* top: -36px; 
    left: 20px;  */
    top: -78px;
    right: 12px;
}

.Cici_cici__question-text__2-Ebg {
    color: #334048;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5; 
    margin: 0

}

.Cici_cici__help-text__3RlXm {
    /* font-size: 14px;
    color: #555;
    margin: 4px 0 0 0; */
    color: #334048;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    margin: 4px 0 0 0;
    margin-top: 0px;
}

.Cici_cici__close-button__CeBFk {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
   
}

.Cici_cici__close-button__CeBFk img {
    width: 7.998px;
    height: 8px;
    object-fit: contain;
    margin-left: -12px;
}

.Cici_cici__close-button__CeBFk:hover img {
    background-color: transparent;
    border: none;
    border-color: none;
}

.Cici_cici__close-button__CeBFk:hover{
    background-color: transparent;
    border: none;
    border-color: none;
}

.Cici_cici__close-button__CeBFk:focus {
    outline: none; 
}
.Cici_cici__open-button__3s9Ru:hover ~ .Cici_cici__text-box__1orz_  {
    /* margin-left: 60px; 
    opacity: 1;
    top: 43px; */
}
.ChatbotLayout_cca-chatbot__1L7LH {
  position: relative;
  border-radius: 8px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: -4px -4px 20px 0px rgba(0, 0, 0, 0.25);
  z-index: 4;
  background-color: white;

  /**
  * 100vh is wonky in ios use dvh and vh as a backup
  **/
  height: 100dvh;
}

@media only screen and (min-width: 576px) {
  .ChatbotLayout_cca-chatbot__1L7LH {
    width: 360px;
    height: 672px;
    max-height: calc(100vh - 64px);
    margin: 0px;
    margin: 32px;
  }
  /* .cca-chatbot__webchat {
    position: sticky;
    overflow-y: auto;
  } */
}

.ChatbotLayout_cca-chatbot--bordered__390nW {
  border: 1px solid #e0e0e0;
}

.ChatbotLayout_cca-chatbot__header__1AAPF {
  height: 56px;
  flex-shrink: 0;
  flex-grow: 0;
}

.ChatbotLayout_cca-chatbot__webchat__1qjV4 {
  position: relative;
  flex-shrink: 0;
  flex-grow: 1;
  height: calc(100% - 101px);
}

.ChatbotLayout_cca-chatbot__wizard__26OMs {
  position: absolute;
  top: 56px;
  left: 0;
  background-color: aqua;
  width: 100%;
  height: calc(100% - 56px);
  z-index: 1;
}

.ChatbotLayout_css-1m1alj7__18TSJ {
  display: none;
}

.ChatbotLayout_cca-chatbot__wizardMobile__3QTj4 {

  height: 100vh !important;
}
.ChatbotLayout_cca-chatbot__footer__GI6XS {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 45px;
  margin-top: auto;
} 



:root {
  --primary: #2d77a3;
  --mobile-breakpoint: 575px;
  --CalHEERS-Primary-Text: #334048;
  --CalHEERS-Divider-Gray: #d1d1d1;
  --Other-Divider: #d1d1d1;
}
.Header_header__ng1ed {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid var(--CalHEERS-Divider-Gray);
}

.Header_header__title__2_4oA {
  flex-basis: 70%;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
}

.Header_header__menu-toggle__8hLvM {
  flex-basis: 15%;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: auto;
  display: flex;
  justify-content: start;
  align-items: center;
}

.Header_header__close__WQcRL {
  flex-basis: 15%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: end;
  align-items: center;
}

.HeaderCloseButton_header-close-button__OxsZP {
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    background: white;
}
.HeaderMenuToggle_header-menu-toggle__2TADz {
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    border: none;
    outline: none !important;
}

.HeaderMenuToggle_header-menu-toggle__2TADz {
    outline: none;
  }
  
  .HeaderMenuToggle_header-menu-toggle__2TADz:focus {
    outline: 2px solid #007BFF !important; 
    outline-offset: 2px; 
  }
  
.HeaderTitle_header-title__3VVLQ {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.HeaderTitle_header-title__text__cdthb {
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
}

.HeaderTitle_header-title__icon__37FfH {}
.Footer_footer__2TJ98 {
    position: relative;
    height: 100%;
    width: 100%;
}

.Footer_footer__language-options__gaD1U {
    height: 100%;
    width: 100%;
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.FooterLanguageOptions_footer-language-options__kPOZ3 {
  font-size: 12px;
  color: #696969;
  border: none;
}


.FooterLanguageOptions_footer-language-options__kPOZ3 {
  margin-left: auto; 
}

/* EndChatButton.module.css */
.EndChatButton_endChatButton__1K11l {
    background: none;
    border: none;
    font-size: 12px;
    color: #696969;
    border: none;
    padding: 0 10px;
    margin-left: auto;
    position: absolute;
    left: auto;
    right: 10px;
}

.EndChatButton_endChatButton__1K11l:disabled {
    color: #aaa;
    cursor: not-allowed;
    text-decoration: none;
}

:root{
  header-max-height: 64px;
  header-margin-bottom: 32px;
  footer-max-height: 75px;
  body-max-height: calc(100% - var(header-max-height) - var(header-margin-bottom) - var(footer-max-height));
}

.ChatMenu_chat-menu__3yeat {
  position: absolute;
  z-index: 2;
  height: calc(100% - 56px);
  max-height: calc(100% - 56px);
  width: 100%;
  top: 56px;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  visibility: visible;
  transition: 0.3s;
}

.ChatMenu_chat-menu--inactive__2EqTI {
  transform: translateX(-110%);
  -webkit-transform: translateX(-110%);
  visibility: hidden;
}

.ChatMenu_chat-menu__header__2f68C {
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 20px;
}

.ChatMenu_chat-menu__header-back-button-container__20pDx {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-self: center;
}

.ChatMenu_chat-menu__header-back-button__zANde {
  padding-left: 16px;
  padding-right: 16px;
  background-color: white;
  border: none;
}

.ChatMenu_chat-menu__header-text__1qx0G {
  font-size: 21px;
  font-weight: bold;
}

.ChatMenu_chat-menu__body__24Yl- {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: var(body-max-height);
  overflow-y: auto;
}

.ChatMenu_chat-menu__content__Fd4a5 {}

.ChatMenu_chat-menu__items-title__3MFxV {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.ChatMenu_chat-menu__items__CyUl7 {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}

.ChatMenu_chat-menu__item__2pRnK {
  border: none;
  background-color: white;
  color: #2d77a3;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 14px;
  border-bottom: 1px solid #d1d1d1;
}

.ChatMenu_chat-menu__item__2pRnK:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.ChatMenu_chat-menu__main-actions__25czh {
  flex-grow: 1;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
}

.ChatMenu_chat-menu__main-action__1aZIR {
  border: none;
  background-color: #2d77a3;
  color: white;
  height: 43px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 3px;
  margin: auto;
  width: 100%;
}

.ChatMenu_chat-menu__footer__1v8SR {
  margin-top: auto;
  background-color: #f2f2f2;
}

.ChatMenu_chat-menu__footer-items__1aHns {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.ChatMenu_chat-menu__footer-item__Z8yOo {
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: #696969;
  padding-top: 8px;
  padding-bottom: 8px;
}

/**
* Accordion inside chat menu
**/
.ChatMenu_chat-menu__accordion__3WEOR{
    border: 5px solid blue !important
}
/* Pill.module.css */
.Pill_pill__2smpX {
    padding: 6px 12px;
    border-radius: 20px;
    border: 1px solid #2D77A3;
    background-color: white;
    display: inline-flex;
    margin: 8px;
    font-weight: 600;
}

.Pill_pill__2smpX:focus {
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px !important;
    outline: none !important;
}

.Pill_pill--primary__1yGat {
    background-color: var(--Primary-Main, white);
    border-color: var(--Primary-Main, #2D77A3);
    color: var(--Primary-Main, #2D77A3);
}

.Pill_pill--primary__1yGat:hover {
    background-color: #235F82 !important;
    border-color: #235F82 !important;
    color: white !important;
}

.Pill_pill--primary__1yGat:active {
    background-color: #004B74;
    border-color: #004B74;
    color: white;
}

.Pill_pill--active__3RApn {
    background-color: #2D77A3;
    border-color: #2D77A3;
    color: white;
}

.Pill_pill--outline-primary__1-VeW {
    color: var(--Primary-Main, #2D77A3);
    border-color: var(--Primary-Main, #2D77A3);
}

.Pill_pill--outline-primary__1-VeW:hover {
    border-color: #235F82;
    color: #235F82;
}

.Pill_pill--outline-primary__1-VeW:active {
    color: white;
    background-color: #2D77A3;
    border-color: #2D76A3;
}

.Pill_pill__icon__25AVZ {
    margin-right: 6px;
}

.Pill_pill__icon--active__ftriR svg path {
    fill: white;
}

.Pill_pill__icon--active__ftriR {
    display: flex;
}

.ChatMenuAccordion_chat-menu-accordion__3DhmE{
    border: #D1D1D1;

}

.ChatMenuAccordion_chat-menu-accordion__item__23WfP{
    border: none;
    outline: none;
    border-radius: 0;
    border-top: 1px solid !important;
    border-color: #D1D1D1 !important; 
    position: static !important;
    z-index: 5000;
}

.ChatMenuAccordion_chat-menu-accordion__header__hwtz4{
    padding: 0 ;
    border: none ;
    outline: none;
    border-top: 1px ;
}

.ChatMenuAccordion_chat-menu-accordion__toggle__3huej{
    background-color: white;
    border: none;
    outline: none !important;
    display: flex;
    height: 50px;
    padding: 16px 12px;
    position: relative;
    width: 100%;
    font-weight: 600;
}

.ChatMenuAccordion_chat-menu-accordion__toggle--active__k5xV7,
.ChatMenuAccordion_chat-menu-accordion__toggle__3huej:hover{
    background-color: #F2F2F2 ;
}
.NavigationButton_navigation-button__2uzBt {
    display: flex;
    height: 43px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    align-self: stretch;
    border-radius: 3px;
    border: none;

    flex-direction: column;
    justify-content: center;
    flex: 1 0;
    align-self: stretch;
    text-align: center;

    /* Desktop/Button */
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;

    width: 100%;
}

.NavigationButton_navigation-button--primary__38Htx {
    background: var(--Primary-Main, #2D77A3);
    color: #FFF;
}

.NavigationButton_navigation-button--secondary__3tKP- {
    border: 1px solid var(--Primary-Main, #2D77A3);
    background: #FFF;
    color: var(--Primary-Main, #2D77A3);
}

.NavigationButton_navigation-button--tertiary__1QEri {
    background: #FFF;
    color: var(--Primary-Main, #2D77A3);
}

.NavigationButton_navigation-button--primary-disabled__7JBNp,
.NavigationButton_navigation-button--secondary-disabled__1lOLq,
.NavigationButton_navigation-button--tertiary-disabled__62Mzw {
    /* background-color: gray !important;
    color: black !important; */
    cursor: not-allowed !important;
}
.Pill_pill__2smpX:focus {
    outline: 2px solid #0056b3 !important;
  }
  
  .WebchatMenu_accordionIcon__14Wxz:focus-visible {
    outline: 3px solid #64A6D4 !important 
  }
    
.SuggestedActions_suggested-actions__rXyja{
    padding: 8px 12px;
    margin-bottom: 32px;
}
.SuggestedActions_suggested-actions__action__1RYpM{
    background: none;
    border: 1px solid #2D77A3;
    color: #2D77A3;
    border-radius: 20px;
    margin: 8px;
    padding: 6px 12px;
    text-align: start;
    font-size: 14px;
    font-weight: 600;
}
.WelcomeScreen_welcome-screen__1UB1r{
    position: absolute;
    height: calc(100% - 40px);
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

@media only screen and (min-width: 576px) {
    /* .welcome-screen{
        position: absolute;
        overflow-y: auto;
    } */
}
.WelcomeScreen_welcome-screen__image-container__tSuSe{
    margin-bottom: 8px;
}

.WelcomeScreen_welcome-screen__heading__34BaM{
    width: 100%;
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    margin-top: 8px;
}
.WelcomeScreen_welcome-screen__sub-heading__2U8ZP{
    font-size: 14px;
    width: 100%;
    /* margin-bottom: 32px; */
    line-height: 150%;
}

.WelcomeScreen_welcome-screen__actions-container__KFlqY{
    margin-left: -8px;
    margin-right: -8px;
}
.WelcomeMessage_welcome_lottie_avatar__xDdd6 {
    display: flex;
}
.WelcomeScreenSuggestedActions_welcome-screen-suggested-actions__OD8WF{
    /* margin-top: 32px; */
}
.WelcomeScreenSuggestedActions_welcome-screen-suggested-actions__action__4Uk2e{}
.SuggestedAction_suggested-action__29Gob{
    background: none;
    border: 1px solid #2D77A3;
    color: #2D77A3;
    border-radius: 20px;
    margin: 8px;
    padding: 6px 12px;
    text-align: start;
    font-size: 14px;
    font-weight: 600;
}
.feedbackAttachment {
  text-align: center;
  padding: 5px;
  /* background-color: #e6e7e8; */
  font-family: Calibri, "Helvetica Neue", Arial, sans-serif;
  line-height: 1;
}
.feedbackAttachment__input{
  display: none;
  position: absolute;
  margin-left: -9999px;
}
.feedbackAttachment__label{
  cursor: pointer;
}
.feedbackAttachment__button-div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.feedbackAttachment__button{
  min-height: 45px;
  font-weight: 600;
}
.feedbackAttachment__submitted {
  color: #008B00;
  font-size: 36px;
}

.shakeButton {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake .5s; 

  /* When the animation is finished, start again */
  animation-iteration-count: 1; 
}

@keyframes shake {
  0% { transform: translate(5px, 0px) rotate(0deg); }
  10% { transform: translate(-5px, 0px) rotate(0deg); }
  20% { transform: translate(4px, 0px) rotate(0deg); }
  30% { transform: translate(-4px, 0px) rotate(0deg); }
  40% { transform: translate(3px, 0px) rotate(0deg); }
  50% { transform: translate(-3px, 0px) rotate(0deg); }
  60% { transform: translate(2px, 0px) rotate(0deg); }
  70% { transform: translate(-2px, 0px) rotate(0deg); }
  80% { transform: translate(1px, 0px) rotate(0deg); }
  90% { transform: translate(-1px, 0px) rotate(0deg); }
  100% { transform: translate(1px, 0px) rotate(0deg); }
}

.text-body {
  color: #212529!important;
  font-weight: 400!important;
}
.margin-b-0 {
  margin-bottom: 0!important;
}
.width-100 {
  width: 100%;
}
.cici-loading {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-width: 3px;
  vertical-align: text-bottom;
  border: 4px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: cici-loading .75s linear infinite;
}

@keyframes cici-loading {
  to { transform: rotate(360deg); }
}

.error-message {
  color: red !important;
  font-weight: bold;
}
.star-rating__checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.star-rating__star {
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.5em;
  color: #ABABAB;
  transition: color .2s ease-out;
}
.star-rating__star:hover {
  cursor: pointer;
}
.star-rating__star.is-selected {
  color: #FFD700;
}
.liveChatCard-container{
    padding: 15px;
}

.liveChatCard-fields{
    padding: 4px;
}

.liveChatCard-fields-dropdown{
    padding: 7px;
}

.liveChatCard-fields__outer{
    padding-top: 4px;
    padding-bottom: 4px;
}

.liveChatCard-label{
    font-size:15px;
}

.liveChatCard-label-small{
    font-size:12px;
    padding-bottom: 8px;
}


.liveChatCard-fields__label{
    margin-bottom: 0!important;
}
.welcomeAttachment {
  padding: 5px;
  /* background-color: #e6e7e8; */
  font-family: Calibri, "Helvetica Neue", Arial, sans-serif;
  line-height: 1;
}

.text-body {
  color: #212529 !important;
  padding: 2px 7px;
  /* font-weight: 400 !important; */
}
.margin-b-0 {
  margin-bottom: 0 !important;
}
.width-100 {
  width: 100%;
}

.arp-dispatch {
  color: #4f63f9;
  cursor: pointer;
}
.arp-dispatch:hover {
  color: #0824f3;
}

.WizardLayout_wizard__2E5MZ {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 50px;
}

.WizardLayout_wizard__header__1igso {
  width: 100%;
  position: absolute;
  margin: -20px -20px 0 -20px;
  display: flex;
  justify-content: flex-end;
}

.WizardLayout_wizard__content__3Zg8v {
  margin: -20px -20px 0 -20px;
  padding-top: 64px;
  /* flex-grow: 1; */
  height: calc(100% - 125px); 
  overflow-y: auto; 
  padding: 20px;
  z-index: 1;
}

.WizardLayout_wizard__2E5MZ ul {
  margin: 0;
  padding-left: 20px;
}

.WizardLayout_wizard__2E5MZ p {
  margin: 0;
}

.WizardLayout_wizard__2E5MZ ul li {
  padding-bottom: 16px;
}

.WizardLayout_overlay__1SNDz {
  position: absolute;
    top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:red; 
  z-index: 1000;
}
.WizardLayout_wizard__content__3Zg8v::-webkit-scrollbar {
  width: 4px;
  background-color: #BABABC;
  border-radius: 4px;
  /* display: none;  */
}

/* .wizard__content::-webkit-scrollbar::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 51px;
  background: linear-gradient(to top, white, transparent);
  pointer-events: none;
} */
/* .wizard__content:hover::-webkit-scrollbar {
  display: block; 
} */

.WizardLayout_wizard__navigation__3NxUS {
  margin-top: auto;
}

.WizardLayout_wizard__navigation-button-container__19ikH {
  margin-bottom: 16px;
}

.WizardLayout_wizard__navigation-button-container__19ikH:last-child {
  margin-bottom: 0;
}

.WizardLayout_overlaybottom__39Cr0 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -38px;
  height: 112px; 
  border-radius: 0px 0px 9px 9px;
  background:  linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 39.11%);
  pointer-events: none; 
  z-index: 2;
}

.WizardLayout_overlaybottom--first__3ERCL {
  bottom: -106px;
  height: 125px;
}

.WizardLayout_overlaybottom--passcode__2VQ3b {
  bottom: -29px !important;
  height : 24px !important
}
.PageLayout_page__1q4JP {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.PageLayout_page__header__5CX4_ {}

.PageLayout_page__content__2f01o {}

.PageLayout_page__navigation__3DGEF {
    margin-top: auto;
}

.PageLayout_page__navigation-button-container__2px1_ {
    margin-bottom: 16px;
}

.PageLayout_page__navigation-button-container__2px1_:last-child {
    margin-bottom: 0;
}
.SubHeader_cici_subHeader_contianer__tet2Z {
  display: flex;
  height: 22px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0;
}

.SubHeader_cici_subHeader_typography__13dcm {
  color: var(--CalHEERS-Primary-Text, #334048);
  font-family: "Open Sans";
  font-size: 21px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 135%;
}

.Body_cici_bodyText__31MPU{
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
}
.Header_wizard-header__38dRZ {
    display: flex;
    height: 64px;
}

.Header_title__24IbW {
    color:var(--CalHEERS-Main-Blue, #2D77A3);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    align-items: center;
    padding: 20px;
    margin-top: -38px;
    cursor: pointer;
}
.HeaderButton_wizard-header-button__17qOI {
    border: none;
    background: white;
    padding: 20px;
    font-size: 14px;
    font-weight: normal;
    color: var(--primary);
}
.InputValidationMessage_input-validation-message__2jloQ {
    color: #E22E3F;
    font-size: 14px;
    margin-top: 8px;
    font-style: italic;
}

.InputValidationMessage_otp-validation-message__2VTKS {
    color: #E22E3F;
    font-size: 14px;
    margin-top: -24px !important;
    margin-bottom: 32px !important;
    font-style: italic;
  }
  
.chatbot-select {}

.chatbot-select--invalid {}

.chatbot-select--invalid .chatbot-select__control {
  border: 1px solid #e22e3f;
}

.chatbot-select__placeholder {
  font-style: italic;
  color: var(--Text-Inactive, #949494);
}

.chatbot-select__menu {
  margin-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: 1px solid #e0e0e0 !important;
  border-color: black !important;
  border-top: none !important;
  box-shadow: none !important;
}

.chatbot-select__control {
  height: 40px !important;
}

.chatbot-select__control--is-focused {
  border-color: black !important;
  box-shadow: none !important;
}

.chatbot-select__control--menu-is-open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: black !important;
  border-bottom-color: var(--Other-Divider) !important;
  box-shadow: none !important;
}

.chatbot-select__menu-list {
  padding: 0 !important;
}

.chatbot-select__option:first-child {
  border-top: none;
}

.chatbot-select__option {
  border-bottom: 1px solid var(--Other-Divider) !important;
}

.chatbot-select__option--is-focused {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.chatbot-select__option--is-selected {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: var(--CalHEERS-Primary-Text) !important;
}

.chatbot-select__indicator {
  color: var(--primary) !important;
}

.chatbot-select__control--menu-is-open .chatbot-select__indicator svg {
  transform: rotate(180deg);
}
.Form_chatbot-form__1HgJ0 {}

.Form_chatbot-form__group__3oXjJ {
    margin-top: 32px;
}

.Form_chatbot-form__label__UYecp {
    display: flex;
    margin-bottom: 8px;
}

.Form_chatbot-form__control__1J5i8 {
    margin-bottom: 8px;
}

.Form_chatbot-form__group--is-invalid__3YBp5 .Form_chatbot-form__control__1J5i8 input,
.Form_chatbot-form__group--is-invalid__3YBp5 .Form_chatbot-form__control__1J5i8 select {
    border: 1px solid red;
}

.Form_chatbot-form__group--is-invalid__3YBp5 .Form_chatbot-form__validation-message__1a6O- {
    display: block;
}

.Form_chatbot-form__validation-message__1a6O- {
    display: none;
    color: red;
    font-style: italic;
}

.Form_hidden__Kao6O {
    display: block;
}
.chatbot-radio {
  display: flex;
  flex-direction: column;
}

.chatbot-radio__option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.chatbot-radio .chatbot-radio__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.chatbot-radio__label {
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: var(--CalHEERS-Primary-Text, #334048);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.custom-radio {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 10px;
  margin-top: 0px !important;
}

.chatbot-radio__icon {
  width: 16px;
  height: 16px;
  display: block;
}

.chatbot-radio--invalid .chatbot-radio__label {
  color: red;
}

.chatbot-radio--invalid .custom-radio {
  border-color: red;
}
.chatbot-radio__option:first-of-type .custom-radio {
  margin-top: -14px; 
}
.Input_input__text__18c9d {
    color: var(--Text-Primary, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    outline: none !important;
    width: 100%;
    border: 1px solid var(--Grayscale-Gray---200, #BABABC);
    display: flex;
    padding: 10.5px 12px;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    background: var(--Background-Default, #FFF);
}

.Input_input__text__18c9d::placeholder {
    font-style: italic;
    color: var(--Text-Inactive, #949494);
}

.Input_input__text--normal__3Aohh {
    font-style: normal;
}

.Input_input__text--italic__1rFnN {
    font-style: italic;
}

.Input_input__text--invalid__39B_A {
    border: 1px solid red;
}

.Input_cici_input_container_invalid__bFis_ {
    display: flex;
    padding: 10.5px 12px;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    width: calc(100%);
    border: 1px solid red;
    background: var(--Background-Default, #FFF);
}

.Input_cici_input_container_invalid_feedback__2tMYR {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    color: var(--Error-Main, #E22E3F);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
}

.Input_cici_input_container_valid_feedback__3zn0b {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545 !important;
}

.Tooltip_tooltipContainer__BDsnW {
  position: relative;
  display: inline-block;
}

.Tooltip_tooltipIcon__p_aZa {
  cursor: pointer;
  width: 13px;
  height: 13px;
  margin-bottom: 3px;
  margin-left: 4px;
}

.Tooltip_tooltipMessage__1nofP {
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 16px;
  background: var(--CalHEERS-Primary-Text, #334048);
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  color: var(--Primaries-White, #FFF);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 21px; /* 150% */
  text-align: left;
  width: var(--tooltip-width, 20px); /* Default width */
  height: var(--tooltip-height, auto); /* Default height */
}

.Tooltip_tooltipMessageTop__RPEzQ.Tooltip_en__PWWeJ {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}

.Tooltip_tooltipMessageTop__RPEzQ.Tooltip_es__kArZk {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 10px;
}
.Tooltip_tooltipMessageBottom__3QXsx.Tooltip_en__PWWeJ {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.Tooltip_tooltipMessageBottom__3QXsx.Tooltip_es__kArZk {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.Tooltip_tooltipMessageLeft__2m4hq.Tooltip_en__PWWeJ {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;
}

.Tooltip_tooltipMessageLeft__2m4hq.Tooltip_es__kArZk {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 10px;
}
.Tooltip_tooltipMessageRight__3tudS.Tooltip_en__PWWeJ {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}
.Tooltip_tooltipMessageRight__3tudS.Tooltip_es__kArZk {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
}

/* @media (min-width: 765px) and (max-height: 1025px) {
  .tooltipMessageLeft.es {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 10px;
  }
} */

/* @media (max-width: 480px) {
  .tooltipMessage {
    width: var(--tooltip-width-small-mobile, 150px) !important;
    height: var(--tooltip-height-small-mobile, auto); 
  }
} */

.Edit_cici_edit__1Xhm2 {
    border-radius: 20px;
    border: 1px solid var(--Primary-Main, #2D77A3);
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 6px;
    gap: 6px;
    /* width:  calc(100%); */
    width: auto;
    background-color: white;
}

.Edit_cici_editText__1SQ8V {
    color: var(--Primary-Main, #2D77A3);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}

.Edit_cici_editImage__27jP8 {
    width: 14px;
    height: 14px;
    margin-top: 2px;
}
.Panel_panel__3peFD {
    padding: 20px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    margin-bottom: 24px;
}

.Panel_panel__header__o11Lg {
    font-size: 18px;
    color: #334048;
    font-weight: 700;
    border-bottom: 1px solid #D1D1D1;
    padding-bottom: 12px;
    line-height: 28px;
}

.Panel_panel__content__3loQE {
    padding-top: 12px;
    line-height: 21px;
}
.Expansion_explanationToggle__2sMzU {
    margin-bottom: 1rem;
  }
  
  .Expansion_toggleHeader__1FY8E {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--CalHEERS-Main-Blue, #2D77A3);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal !important;
    font-weight: 400;
    line-height: 150%;
  }
  
  .Expansion_toggleHeading__2wqu9 {
    margin: 0;
    margin-right: 8px; 
    display: inline; 
    font-weight: normal !important;
    font-size: 14px
  }
  
  .Expansion_toggleIcon__Xv-gg {
    cursor: pointer;
    width: 8px;
    height: 5px;
    fill: var(--CalHEERS-Main-Blue, #2D77A3);
    margin-bottom: -4px;
  }
  
  .Expansion_messageDetails__3mwSW {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
  }
  
.Alert_cici_Alert_outerFrame__2Vmn2 {
    display: flex;
    align-items: stretch; 
    width: 100%;
    border-radius: 3px;
    background: var(--Background-Secondary, #F2F2F2);
}

.Alert_cici_Alert_leftFrame__pL8pX {
    width: 7px;
    flex-shrink: 0;
    /* background: var(--Warning-Main, #F1BD19); */
}

.Alert_cici_Alert_text_spacing__3_W6w {
    display: flex;
    align-items: flex-start; 
    padding: 12px;
    grid-gap: 12px;
    gap: 12px;
    flex: 1 1;
}

.Alert_cici_Alert_icon__200dZ {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 20px;
    height: 20px;
    flex-shrink: 0; 
}

.Alert_cici_Alert_text__2KSUy {
    color: var(--Text-Primary, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px; 
    letter-spacing: 0.25px;
    display: flex;
    flex-direction: column; 
}

.Alert_cici_Alert_highlightedMessage__32Dsm {
    color: var(--Text-Primary, #334048);
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.25px;
}


.Modal_modal__3nZ-D {
    position: absolute;
    display: block;
    opacity: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(7, 7, 7, 0.44);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.Modal_modal--fixed__1Gv55{
    position: absolute;
}

.Modal_modal__header__AlvzQ {
    height: 24px;
    display: flex;
    margin-bottom: 16px; 
}


.Modal_modal__close__2E2ki {
    margin-left: auto;
    cursor: pointer;
    border: none;
    background-color: white;
    padding: 0;
}

.Modal_modal__close__2E2ki img {
    height: 100%;
}

.Modal_modal__dialog__fv9PM {
    margin: auto;
    width: 280px;
    height: 266px;
    z-index: 6;
    background: white;
    padding: 8px 8px 24px 8px;
    border-radius: 4px;
}

.Modal_modal__body__3hZ_z {
    padding-left: 8px;
    padding-right: 8px;
}

.Modal_modal__title__8bTGH {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
}

.Modal_modal__content__W4S9V {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
    /* 171.429% */
}


.Modal_modal--inside-wizard__1XKeR {
    position: fixed !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(7, 7, 7, 0.44);
    z-index: 1000;
  
}

.Modal_modal--inside-wizard__1XKeR .Modal_modal__dialog__fv9PM {
    width: 280px;
    max-width: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 416px;
    position: relative;
    left: 38%;
    top: 11%;
}

@media (max-width: 700px) {
    .Modal_modal--inside-wizard__1XKeR .Modal_modal__dialog__fv9PM {
        left: -1% !important; 
        top: 3% !important;
    }
}


@media (min-width: 701px) and (max-width: 1024px) {
    .Modal_modal--inside-wizard__1XKeR .Modal_modal__dialog__fv9PM {
        left: 22% !important; 
        top: 12% !important;
    }
}


@media (min-width: 1025px) and (max-width: 1800px) {
    .Modal_modal--inside-wizard__1XKeR .Modal_modal__dialog__fv9PM {
        left: 38%;  
        top: 11%;   
    }
}


@media (min-width: 1801px) and (max-width: 3000px) {
    .Modal_modal--inside-wizard__1XKeR .Modal_modal__dialog__fv9PM {
        left: 39%; 
        top: 11%;
    }
}
.Modal_passcode-modal__2x3gr .Modal_modal__dialog__fv9PM {
    height: 300px; 
}

.person-selection {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.person-details-person-selection {
    margin-right: 20px;
    flex: 1 1;
}

.person-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
    grid-gap: 20px;
    gap: 20px; /* Space between the columns */
    flex: 1 1;
    min-width: 200px; /* Ensure the container has enough space */
}

.person-options input[type="radio"] {
    display: none; /* Completely hide the native radio button */
}

.person-options label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 30px; /* Add space for the custom radio button */
}

.icon-container {
    display: inline-block;
    width: 16px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 25%; /* Move the radio button up */
    transform: translateY(-50%);
}

.person-options label span.keep-cancel-option {
    font-size: 14px;
}

.person-options.validation-error label .icon-container {
    /* No need for border here, we'll use the ErrorIcon */
}

.person-options input[type="radio"]:checked + label .icon-container {
    border: none;
}

.person-name {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.person-age {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 21px;
}

.keep-cancel-option {
    color: var(--Text-Primary, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}

.plan-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.plan-group {
    display: flex;
    flex-direction: column;
}

.plan-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.plan-text {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}

.group {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}

.plan-name {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; 
}

.what-you-pay {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; 
}

.monthly-cost {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; 
}

.monthly-text {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

/* .plan-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.plan-group {
    display: flex;
    flex-direction: column;
}

.plan-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
} */

.plan-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.plan-group {
    display: flex;
    flex-direction: column;
}

.plan-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.group {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 175% */
}

.plan-name {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 150% */
}

.what-you-pay {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 175% */
}

.adjusted-cost {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; /* 100% */
}

.monthly {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}


.info-icon {
    font-style: normal; 
    width: 13px;
    height: 13px;
    margin-top: -3px
}

.person-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.person-details {
    display: flex;
    align-items: center;
}

.status-icon {
    margin-right: 8px;
    width: 10.819px;
    height: 6.147px;
    margin-top: -37px;
}

.status-text {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
}


.person-name {
    color: var(--CalHEERS-Primary-Text, #334048);
      font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
}

.person-age {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px; 
}

.keep-cancel-status {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
}

.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loadingSpinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
.loading-indicator-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keep-or-cancel-selection {
    display: flex;
    align-items: center;
    
}

.opt-options-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
 /* This ensures the spacing is distributed */
}

.opt-options-inline input[type="radio"] {
    margin-right: 5px; /* Adds 5px space between the radio button and label */
}


.opt-options-inline label {
    margin-right: 20px; /* Adds 20% space between "Keep" and "Cancel" */
}


/* ResetPasswordPage.css */
input.form-control::placeholder {
    color: var(--Text-Inactive, #949494);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    
  }
  
  /* Additional link styles */
  .link-style {
    color: var(--CalHEERS-Main-Blue, #2D77A3);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
  }
  
.planCard_planCard__1md4w {
  width: 100%;
  color: #334048;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  line-height: 21px;
  padding-bottom: 24px;
}

  .planCard_planCardHeader__UbjXG {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .planCard_planType__wglt2 {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
  }

  .planCard_status__1SISm {
    display: flex;
    align-items: center;
  }

  .planCard_statusDot__3S0y8 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .planCard_statusText__4r5rR {
    margin: 0;
    font-size: 14px;
  }

  .planCard_planCardDivider__iZfzZ {
    margin: 8px 0;
    border: 0;
    border-top: 1px solid #D1D1D1;
  }

  .planCard_planCardBody__2a4YW {
    margin-top: 8px;
  }

  .planCard_planDetail__32fXL {
    margin: 8px 0;
  }

  .planCard_planDetailLabel__3BhvD {
    display: block;
    font-weight: bold;
    font-size: 12px;
  }

  .planCard_planDetail__32fXL span {
    display: block;
    font-size: 12px;
    color: #334048;
  }

input.form-control::placeholder {
    color: var(--Text-Inactive, #949494);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.25px;
    
  }
  
  /* Additional link styles */
  .link-style {
    color: var(--CalHEERS-Main-Blue, #2D77A3);
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
  }
.NextButton_cici_nextButton_border__2O-97 {
    display: flex;
    /* min-width: 216px; */
    padding: 4px 16px 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background: #FFF;
    width: calc(100%);
}

.NextButton_cici_nextButton_button__a0k92{
    display: flex;
    height: 43px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    align-self: stretch;
    border-radius: 3px;
    background: var(--Primary-Main, #2D77A3);
    border: none;
}

.NextButton_cici_nextButton_text__42T9h {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0;
    align-self: stretch;
    color: #FFF;
    text-align: center;

/* Desktop/Button */
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}
.BackButton_cici_backButton_border__2ZvXh {
    display: flex;
    /* min-width: 216px; */
    padding: 4px 16px 20px 16px;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background: #FFF;
    width: calc(100%);
}

.BackButton_cici_backButton_button__1LF9I{
    display: flex;
    height: 43px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    align-self: stretch;
    border-radius: 3px;
    border: 1px solid var(--Primary-Main, #2D77A3);
    background: #FFF;

}

.BackButton_cici_backButton_text__2i3W4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0;
    align-self: stretch;
    color: var(--Primary-Main, #2D77A3);
    text-align: center;
    
    /* Desktop/Button */
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
}
.DualButton_cici_dualButton_Container__1FSyn {
    display: flex;
    align-items: center; 
    flex-direction: column;  
    /* gap: 16px */
}


