.cici_Alert_outerFrame {
    display: flex;
    align-items: stretch; 
    width: 100%;
    border-radius: 3px;
    background: var(--Background-Secondary, #F2F2F2);
}

.cici_Alert_leftFrame {
    width: 7px;
    flex-shrink: 0;
    /* background: var(--Warning-Main, #F1BD19); */
}

.cici_Alert_text_spacing {
    display: flex;
    align-items: flex-start; 
    padding: 12px;
    gap: 12px;
    flex: 1;
}

.cici_Alert_icon {
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 20px;
    height: 20px;
    flex-shrink: 0; 
}

.cici_Alert_text {
    color: var(--Text-Primary, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px; 
    letter-spacing: 0.25px;
    display: flex;
    flex-direction: column; 
}

.cici_Alert_highlightedMessage {
    color: var(--Text-Primary, #334048);
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.25px;
}