.wizard-header {
    display: flex;
    height: 64px;
}

.title {
    color:var(--CalHEERS-Main-Blue, #2D77A3);
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    align-items: center;
    padding: 20px;
    margin-top: -38px;
    cursor: pointer;
}