.chatbot-radio {
  display: flex;
  flex-direction: column;
}

.chatbot-radio__option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.chatbot-radio .chatbot-radio__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.chatbot-radio__label {
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: var(--CalHEERS-Primary-Text, #334048);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.custom-radio {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 10px;
  margin-top: 0px !important;
}

.chatbot-radio__icon {
  width: 16px;
  height: 16px;
  display: block;
}

.chatbot-radio--invalid .chatbot-radio__label {
  color: red;
}

.chatbot-radio--invalid .custom-radio {
  border-color: red;
}
.chatbot-radio__option:first-of-type .custom-radio {
  margin-top: -14px; 
}