.feedbackAttachment {
  text-align: center;
  padding: 5px;
  /* background-color: #e6e7e8; */
  font-family: Calibri, "Helvetica Neue", Arial, sans-serif;
  line-height: 1;
}
.feedbackAttachment__input{
  display: none;
  position: absolute;
  margin-left: -9999px;
}
.feedbackAttachment__label{
  cursor: pointer;
}
.feedbackAttachment__button-div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.feedbackAttachment__button{
  min-height: 45px;
  font-weight: 600;
}
.feedbackAttachment__submitted {
  color: #008B00;
  font-size: 36px;
}

.shakeButton {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake .5s; 

  /* When the animation is finished, start again */
  animation-iteration-count: 1; 
}

@keyframes shake {
  0% { transform: translate(5px, 0px) rotate(0deg); }
  10% { transform: translate(-5px, 0px) rotate(0deg); }
  20% { transform: translate(4px, 0px) rotate(0deg); }
  30% { transform: translate(-4px, 0px) rotate(0deg); }
  40% { transform: translate(3px, 0px) rotate(0deg); }
  50% { transform: translate(-3px, 0px) rotate(0deg); }
  60% { transform: translate(2px, 0px) rotate(0deg); }
  70% { transform: translate(-2px, 0px) rotate(0deg); }
  80% { transform: translate(1px, 0px) rotate(0deg); }
  90% { transform: translate(-1px, 0px) rotate(0deg); }
  100% { transform: translate(1px, 0px) rotate(0deg); }
}

.text-body {
  color: #212529!important;
  font-weight: 400!important;
}
.margin-b-0 {
  margin-bottom: 0!important;
}
.width-100 {
  width: 100%;
}
.cici-loading {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-width: 3px;
  vertical-align: text-bottom;
  border: 4px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: cici-loading .75s linear infinite;
  animation: cici-loading .75s linear infinite;
}

@keyframes cici-loading {
  to { transform: rotate(360deg); }
}

.error-message {
  color: red !important;
  font-weight: bold;
}