/* Pill.module.css */
.pill {
    padding: 6px 12px;
    border-radius: 20px;
    border: 1px solid #2D77A3;
    background-color: white;
    display: inline-flex;
    margin: 8px;
    font-weight: 600;
}

.pill:focus {
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px !important;
    outline: none !important;
}

.pill--primary {
    background-color: var(--Primary-Main, white);
    border-color: var(--Primary-Main, #2D77A3);
    color: var(--Primary-Main, #2D77A3);
}

.pill--primary:hover {
    background-color: #235F82 !important;
    border-color: #235F82 !important;
    color: white !important;
}

.pill--primary:active {
    background-color: #004B74;
    border-color: #004B74;
    color: white;
}

.pill--active {
    background-color: #2D77A3;
    border-color: #2D77A3;
    color: white;
}

.pill--outline-primary {
    color: var(--Primary-Main, #2D77A3);
    border-color: var(--Primary-Main, #2D77A3);
}

.pill--outline-primary:hover {
    border-color: #235F82;
    color: #235F82;
}

.pill--outline-primary:active {
    color: white;
    background-color: #2D77A3;
    border-color: #2D76A3;
}

.pill__icon {
    margin-right: 6px;
}

.pill__icon--active svg path {
    fill: white;
}

.pill__icon--active {
    display: flex;
}
