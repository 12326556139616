

.modal {
    position: absolute;
    display: block;
    opacity: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(7, 7, 7, 0.44);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.modal--fixed{
    position: absolute;
}

.modal__header {
    height: 24px;
    display: flex;
    margin-bottom: 16px; 
}


.modal__close {
    margin-left: auto;
    cursor: pointer;
    border: none;
    background-color: white;
    padding: 0;
}

.modal__close img {
    height: 100%;
}

.modal__dialog {
    margin: auto;
    width: 280px;
    height: 266px;
    z-index: 6;
    background: white;
    padding: 8px 8px 24px 8px;
    border-radius: 4px;
}

.modal__body {
    padding-left: 8px;
    padding-right: 8px;
}

.modal__title {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 8px;
}

.modal__content {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;
    /* 171.429% */
}


.modal--inside-wizard {
    position: fixed !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(7, 7, 7, 0.44);
    z-index: 1000;
  
}

.modal--inside-wizard .modal__dialog {
    width: 280px;
    max-width: 350px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 416px;
    position: relative;
    left: 38%;
    top: 11%;
}

@media (max-width: 700px) {
    .modal--inside-wizard .modal__dialog {
        left: -1% !important; 
        top: 3% !important;
    }
}


@media (min-width: 701px) and (max-width: 1024px) {
    .modal--inside-wizard .modal__dialog {
        left: 22% !important; 
        top: 12% !important;
    }
}


@media (min-width: 1025px) and (max-width: 1800px) {
    .modal--inside-wizard .modal__dialog {
        left: 38%;  
        top: 11%;   
    }
}


@media (min-width: 1801px) and (max-width: 3000px) {
    .modal--inside-wizard .modal__dialog {
        left: 39%; 
        top: 11%;
    }
}
.passcode-modal .modal__dialog {
    height: 300px; 
}
