.footer-language-options {
  font-size: 12px;
  color: #696969;
  border: none;
}


.footer-language-options {
  margin-left: auto; 
}
