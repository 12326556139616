.welcomeAttachment {
  padding: 5px;
  /* background-color: #e6e7e8; */
  font-family: Calibri, "Helvetica Neue", Arial, sans-serif;
  line-height: 1;
}

.text-body {
  color: #212529 !important;
  padding: 2px 7px;
  /* font-weight: 400 !important; */
}
.margin-b-0 {
  margin-bottom: 0 !important;
}
.width-100 {
  width: 100%;
}

.arp-dispatch {
  color: #4f63f9;
  cursor: pointer;
}
.arp-dispatch:hover {
  color: #0824f3;
}
