/* 
Hides default SuggestedAction component
*/
#chatbot-root .css-nil {
    display: none;
}

/**
* SENDBOX
**/
#chatbot-root .main{
    margin: 0 20px;
    border: solid 1px #E6E6E6;
    border-radius: 4px;
}

/**
* MESSAGE ROW
**/
#chatbot-root .webchat__row{
    margin-left: 10px;
    margin-right: 10px;
}

/**
* USER MESSAGE BUBBLE
**/
#chatbot-root .message .bubble.from-user{
    border-radius: var(--primitive-200, 16px) var(--primitive-200, 16px) 0px var(--primitive-200, 16px);
    border: 1px solid #C7F1FA;
    background: #E4F7F9;
}

/**
* BOT MESSAGE BUBBLE
**/
#chatbot-root .message .bubble{
    margin-top: 16px;
    border-radius: 0px var(--primitive-200, 16px) var(--primitive-200, 16px) var(--primitive-200, 16px);
    border: 1px solid #E4E9ED;
    background: #F1F6F9;
    font-size: 14px;
    max-width: 272px;
    font-family: "Open Sans";
    color: #334048;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
}
#chatbot-root .message .bubble a{
    margin-top: 16px;
    border-radius: 0px var(--primitive-200, 16px) var(--primitive-200, 16px) var(--primitive-200, 16px);
 
    /* background: #F1F6F9;
    font-size: 14px;
    max-width: 272px;
    font-family: "Open Sans"; */
    color: #2D77A3;
    /* font-style: normal;
    font-weight: 400;
    line-height: 135%; */
    text-decoration: underline;
}
#chatbot-root .css-o3xlyv{
    padding: 20px;
}

/**
* BUBBLE TIMESTAMP
**/
.timestamp.transcript-timestamp{
    padding: 12px;
    font-weight: 500 ;
    color: #BABABC;
    margin-right: -12px;
}