.cici {
    display: block;
}

.cici--is-minimized .cici__open-button {
    display: block;
}

.cici--is-minimized .cici__close-button {
    display: block;
}

.cici--is-minimized .cici__body {
    display: none;
}

/* .cici__open-button {
    display: none;
    margin: 32px;
    background-color: transparent; 
    border: none; 
} */

.cici__open-button {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    margin: 32px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 66px;
    position: relative;
}

.cici__open-button:focus {
    outline: none; 
}
.button:focus {
    outline: none; 
}
/* .cici__open-button img {
    background-color: transparent; 
    border: none; 
    box-shadow: none; 
    width: 83.359px; 
    height: 92.023px;
    flex-shrink: 0; 
} */

.cici__open-button img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
}
.cici__open-text {
    font-size: 12px;
    margin-bottom: 8px; 
    color: #000; 
  
}

.cici__close-button {
    background-color: #444;
    border-radius: 50%;
    border: none; 
    width: 35px; 
    height: 28px; 
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25px; 
    right: 35px; 
    cursor: pointer;
    color: white; 
    /* font-size: 24px; */
}

.cici__close-button:hover {
    background-color: #666; 
}

.cici__body {
    display: block;
    position: relative;
}

.cici__open-button img {
    /* transition: all 0.3s ease; 
    width: 96px;
    height: 42px;
    margin-left: 0;  */
    transition: all 0.3s ease;
    width: 96px;
    height: 42px;
    top: -36px;
    position: absolute;
    right: 2px;
}

.cici__open-button img:hover {
    content: url('../../Images/CiCi-Desktop-Hover 1.svg'); 
    /* width: 111px;
    height: 50px;
    transform: scale(1.15);
    margin-left: 0;  */
    /* box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.25), 12px 14px 5px 0px rgba(0, 0, 0, 0.00), 8px 9px 5px 0px rgba(0, 0, 0, 0.01), 4px 5px 4px 0px rgba(0, 0, 0, 0.03), 2px 2px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.06); */
    }


@media (max-width: 576px) {
    .cici__open-button img {
        content: url('../../Images/CiCi-Default-Avatar-Mobile 1.svg');
        width: 86px;
        height: 36px;
        flex-shrink: 0;
        position: absolute;
        top: -16px;
        margin-right: -8px;
    }

    .cici__text-box{
        margin-right: 16px !important;
        top: -55px !important;
    }
}

.cici__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    position: relative;
    margin-right: -12px; 
    margin-left: 32px; 
}

.cici__text-box {
    display: flex;
    flex-direction: column;
    background-color: #f1f5f9; 
    border-radius: 8px;
    /* padding: 12px; */
    margin-bottom: -21px;
    position: absolute;
    width: 130px; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
    margin-right: 32px;
    border-radius: var(--primitive-200, 16px) var(--primitive-200, 16px) 0px var(--primitive-200, 16px);
    border: 1px solid #C7F1FA;
    background: #E4F7F9;
    padding-left: 16px;
    padding-top: 12px;
    padding-right: 24px;
    padding-bottom: 12px;
    text-align: left;
    margin-left: 36px;
    /* top: -36px; 
    left: 20px;  */
    top: -78px;
    right: 12px;
}

.cici__question-text {
    color: #334048;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5; 
    margin: 0

}

.cici__help-text {
    /* font-size: 14px;
    color: #555;
    margin: 4px 0 0 0; */
    color: #334048;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    margin: 4px 0 0 0;
    margin-top: 0px;
}

.cici__close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 0px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
   
}

.cici__close-button img {
    width: 7.998px;
    height: 8px;
    object-fit: contain;
    margin-left: -12px;
}

.cici__close-button:hover img {
    background-color: transparent;
    border: none;
    border-color: none;
}

.cici__close-button:hover{
    background-color: transparent;
    border: none;
    border-color: none;
}

.cici__close-button:focus {
    outline: none; 
}
.cici__open-button:hover ~ .cici__text-box  {
    /* margin-left: 60px; 
    opacity: 1;
    top: 43px; */
}