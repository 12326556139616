@import "../variables.module.css";

.chatbot-form {}

.chatbot-form__group {
    margin-top: 32px;
}

.chatbot-form__label {
    display: flex;
    margin-bottom: 8px;
}

.chatbot-form__control {
    margin-bottom: 8px;
}

.chatbot-form__group--is-invalid .chatbot-form__control input,
.chatbot-form__group--is-invalid .chatbot-form__control select {
    border: 1px solid red;
}

.chatbot-form__group--is-invalid .chatbot-form__validation-message {
    display: block;
}

.chatbot-form__validation-message {
    display: none;
    color: red;
    font-style: italic;
}

.hidden {
    display: block;
}