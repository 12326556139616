.person-selection {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.person-details-person-selection {
    margin-right: 20px;
    flex: 1;
}

.person-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
    gap: 20px; /* Space between the columns */
    flex: 1;
    min-width: 200px; /* Ensure the container has enough space */
}

.person-options input[type="radio"] {
    display: none; /* Completely hide the native radio button */
}

.person-options label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 30px; /* Add space for the custom radio button */
}

.icon-container {
    display: inline-block;
    width: 16px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 25%; /* Move the radio button up */
    transform: translateY(-50%);
}

.person-options label span.keep-cancel-option {
    font-size: 14px;
}

.person-options.validation-error label .icon-container {
    /* No need for border here, we'll use the ErrorIcon */
}

.person-options input[type="radio"]:checked + label .icon-container {
    border: none;
}

.person-name {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.person-age {
    color: var(--CalHEERS-Primary-Text, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 21px;
}

.keep-cancel-option {
    color: var(--Text-Primary, #334048);
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
}
