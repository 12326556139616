.chatbot-select {}

.chatbot-select--invalid {}

.chatbot-select--invalid .chatbot-select__control {
  border: 1px solid #e22e3f;
}

.chatbot-select__placeholder {
  font-style: italic;
  color: var(--Text-Inactive, #949494);
}

.chatbot-select__menu {
  margin-top: 0 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: 1px solid #e0e0e0 !important;
  border-color: black !important;
  border-top: none !important;
  box-shadow: none !important;
}

.chatbot-select__control {
  height: 40px !important;
}

.chatbot-select__control--is-focused {
  border-color: black !important;
  box-shadow: none !important;
}

.chatbot-select__control--menu-is-open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-color: black !important;
  border-bottom-color: var(--Other-Divider) !important;
  box-shadow: none !important;
}

.chatbot-select__menu-list {
  padding: 0 !important;
}

.chatbot-select__option:first-child {
  border-top: none;
}

.chatbot-select__option {
  border-bottom: 1px solid var(--Other-Divider) !important;
}

.chatbot-select__option--is-focused {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.chatbot-select__option--is-selected {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: var(--CalHEERS-Primary-Text) !important;
}

.chatbot-select__indicator {
  color: var(--primary) !important;
}

.chatbot-select__control--menu-is-open .chatbot-select__indicator svg {
  transform: rotate(180deg);
}